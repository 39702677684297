import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable()
export class LoginGuard  {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.authService.getToken();

    if (!token || this.authService.isExpired()) {
      return true;
    }
    const role = this.authService.getRole();
    if (role == "ADMIN") this.router.navigate(['/manager']);
    else if (role == "INTERNAL") this.router.navigate(['/manager']);
    else this.router.navigate(['/agent']);

    return false;
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }
}
